.dashboard {

  &-layout {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include TP {
      gap: 3rem;
    }
    @include DS {
      flex-direction: row-reverse;
    }
    @include DL {
      gap: 4rem;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    @include TP {
      flex-direction: row;
      gap: 2rem;
    }
    @include DS {
      flex-direction: column;
      max-width: 14rem;
      gap: 3rem;
    }
    @include DL {
      flex-direction: column;
      max-width: 18rem;
    }

    hr {
      width: 100%;
      margin: 0;
      @include TP {
        width: 0;
      }
      @include DS {
        display: none;
      }
    }

    .accordion {
      width: 100%;
      @include TP {
        width: 50%;
        border-bottom: none;
        padding-bottom: 0;
      }
      @include DS {
        width: 100%;
      }
    }

    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &--chevron {
        display: block;
        @include TP {
          display: none;
        }
      }

      &--action {
        display: none;
        @include TP {
          display: block;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding: 1rem 0;
      gap: 1rem;

      &--block {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      &--rowblock {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &__services {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @include TP {
      gap: 2rem;
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}