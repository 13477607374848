.datatable {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &.dashboard {
    margin: 0;
    gap: 1rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    @include TL {
      flex-direction: row;
      align-items: center;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }

  &__table {
    display: none;
    @include TP {
      display: block;
    }

    // React Data Table specific
    .rdt {
      &_Table {
        color: $color-shades-black;
        min-height: 2rem;

        .tableArrow {
          display: flex;
          padding: 0.5rem;
          background-color: $color-shades-white;
          border-radius: 1.25rem;
          pointer-events: none;
  
          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    
      &_TableCol_Sortable {
        height: 2.5rem;
        font-size: 0.875rem;
        font-weight: 600;
        color: $color-shades-black;
        text-transform: uppercase;
      }
    
      &_TableRow {
        cursor: pointer;
    
        &:hover {
          background-color: $color-shades-lightGray;
        }
      }
      
      &_TableCell {
        font-size: 1rem;
        height: 3.5rem;
        color: $color-shades-black;
      }
    
      &_Pagination {
        justify-content: space-between;
      }
    }
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include TP {
      display: none;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 20rem;
  
    &__indicator {
      width: 3rem;
      height: 3rem;
      border: 0.5rem solid $color-shades-lightGray;
      border-bottom-color: $color-hues-green;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
    
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5rem 0;
    gap: 2rem;
    
    &__icon {
      display: flex;
      width: 6rem;
      height: 6rem;
      padding: 1rem;
      border: 2px dashed $color-shades-darkGray;
      border-radius: 50%;
  
      svg {
        width: 4rem;
        height: 4rem;
  
        path {
          fill: $color-shades-darkGray;
        }
      }
    }
    
    &__content {
      display: flex;
      flex-direction: column;
      max-width: 30rem;
      text-align: center;
      gap: 0.5rem;
    }
  }

  &__noresults {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5rem 0;
    gap: 2rem;

    &__icon {
      @extend .datatable__empty__icon;
    }

    svg {
      width: 4rem;
      height: 4rem;
    }
  }
}