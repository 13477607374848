.segmentedcontrol {
  display: inline-flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 0.25rem;
  background: $color-shades-white;
  border: 1px solid $color-shades-lightGray;
  border-radius: 0.25rem;
  overflow: hidden;
  gap: 0.25rem;

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }

  &__segment {
    flex: 1;
    position: relative;
    display: block;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 0.125rem;
    padding: 0.75rem;
    z-index: 1;

    h5 {
      cursor: pointer;
      display: block;
      color: $color-shades-darkGray;
    }

    &:hover > h5 {
      color: $color-shades-black;
    }

    &.active {
      color: $color-shades-black;
      background: $color-shades-lightGray;
      border-color: $color-shades-black;

      h5 {
        color: $color-shades-black;
      }

      &.red {
        background-color: transparentize($color-flag-notify, 0.8);
        border-color: $color-flag-notify;
      }

      &.green {
        background-color: $color-hues-green;
        background-color: transparentize($color-hues-green, 0.8);
        border-color: $color-hues-green;
      }
    }
  }
}