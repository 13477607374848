.checkbox,
.checkbox__domain {
  position: relative;
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    appearance: checkbox !important;
  }

  label,
  input {
    display: flex;
  }

  label {
    font-weight: $font-weight-regular;
    font-size: 0.875rem;
    line-height: 1rem;
    padding-left: 0px;
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    padding-left: 2rem;
  }

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    &:checked ~ .checkbox__checkmark,
    &:checked ~ div > div .checkbox__domain__checkmark {
      background-color: $color-hues-green;
    }

    &:checked ~ .checkbox__checkmark::after,
    &:checked ~ div > div .checkbox__domain__checkmark::after {
      display: block;
    }
  }

  &__checkmark {
    cursor: pointer;
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    background-color: $color-shades-white;
    border: 2px solid $color-shades-black;
    border-radius: 0.25rem;
    top: 50%;
    left: 0.75rem;
    transform: translate(-50%, -50%);

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:after {
      content: "";
      left: 7px;
      top: 2px;
      width: 7px;
      height: 14px;
      border: solid #000;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.checkbox__domain {
  border: 1px solid $color-shades-darkGray;
  border-radius: 4px;
  padding: 12px;

  & + .checkbox__domain {
    margin-top: 8px;
  }

  label {
    width: 100%;
    padding-left: 28px;
  }

  &__checkmark {
    top: 50%;
    left: 8px;
    transform: translate(-50%, -50%);
  }
  .--row {
    width: 100%;

    svg {
      opacity: 0.25;
    }

    div {
      display: flex;
      align-items: center;
      font-family: $font-family-secondary;
      font-weight: $font-weight-semi-bold;
      font-size: 14px;
      line-height: 17px;
      color: $color-shades-lightBlack;
      opacity: 0.25;
    }
  }

  input {
    &:checked ~ div > div,
    &:checked ~ div svg {
      opacity: 1;
    }
  }
}
