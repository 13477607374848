footer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3rem;
  border-top: 1px solid $color-shades-lightGray;
  gap: 1.5rem;
  @include TP {
    flex-direction: row;
  }

  .footer__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    @include TP {
      flex-direction: row;
      gap: 2rem;
    }

    li {
      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.25rem;
        color: $color-shades-black;

        svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
}
