.accordion {
  &__title {
    svg {
      width: 2rem;
      height: 2rem;
      transform: rotate(0deg);
      transition: all 0.4s ease-in-out;
    }
  }

  &.active {
    svg {
      transform: rotate(180deg);
    }
  }

  &__content {
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    &.active {
      max-height: 185px;
    }
  }
}
