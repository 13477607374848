@use "sass:map";

@mixin button($val) {
  display: inline-block;
  font-family: $font-family-primary;
  font-weight: $button-font-weight;
  font-size: $button-font-size;
  background-color: $val;
  border: $button-border-style;
  border-radius: $button-border-radius;
  color: map.get($button-colors, "black");
  text-align: center;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  font-stretch: condensed;
  text-transform: uppercase;
  transition: all 0.1s ease-out;

  & + * {
    margin-left: 1rem;
  }

  &:hover {
    color: map.get($button-colors, "black");
    text-decoration: none;
    background-color: map.get($button-colors, "white");
    box-shadow: $button-shadow;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }

  i {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
  }

  @include MS {
    padding: $button-sm-padding-y $button-sm-padding-x;
    line-height: $button-sm-line-height;
    height: 36px;
  }

  @include DS {
    padding: $button-padding-y $button-padding-x;
    line-height: $button-line-height;
    height: 40px;
  }
}

@mixin groupedButton($val) {
  font-family: $font-family-primary;
  display: inline-block;

  & + * {
    margin-left: 1rem;
  }

  &:hover {
    span,
    i {
      color: map.get($button-colors, "black");
      text-decoration: none;
      background-color: map.get($button-colors, "white");
      box-shadow: $button-shadow;
    }
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }

  span {
    display: inline-block;
    font-weight: $button-font-weight;
    font-size: $button-font-size;
    color: map.get($button-colors, "black");
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: $val;
    border-radius: $button-border-radius;
    border: $button-border-style;
    cursor: pointer;
    font-stretch: condensed;
    text-transform: uppercase;
    transition: all 0.1s ease-out;

    & + i {
      margin-left: 2px;
    }
  }

  i {
    color: map.get($button-colors, "black");
    font-weight: $button-font-weight;
    font-size: $button-font-size;
    background-color: $val;
    position: relative;
    display: inline-flex;
    border: $button-border-style;
    vertical-align: middle;
    transition: all 0.1s ease-out;

    & + span {
      margin-left: 2px;
    }
  }

  @include MS {
    i {
      padding: calc($button-sm-icon-padding - 1px) calc($button-sm-icon-padding);
    }

    span {
      line-height: $button-sm-line-height;
      padding: $button-sm-padding-y $button-sm-padding-x;
    }
  }

  @include TL {
    i {
      padding: calc($button-icon-padding - 1px) calc($button-icon-padding);
    }

    span {
      line-height: $button-line-height;
      padding: $button-padding-y $button-padding-x;
    }
  }
}

// Simple button
//
// Simple button class generator in all button colours
// by size (normal and small).

@each $key, $val in $button-colors {
  .--button-#{"" + $key} {
    @include button($val);
  }
}

// Grouped button
//
// Grouped button class generator in all button colours
// by size (normal and small).

@each $key, $val in $button-colors {
  .--button-grouped-#{"" + $key} {
    @include groupedButton($val);
  }
}
