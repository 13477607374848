.blank-page {
  h2,
  h3,
  p,
  li {
    text-align: left;
    color: #0a0a29;
  }

  h3 {
    margin-top: 48px;
  }
  p,
  li {
    font-family: $font-family-secondary;
    font-weight: $font-weight-regular;
    font-size: 18px;
    line-height: 25px;
  }

  p {
    & + p {
      margin-top: 24px;
    }

    &:nth-last-child(1) {
      margin-bottom: 24px;
    }
  }
  &__subtitle {
    margin-bottom: 24px;
  }

  ul {
    li {
      position: relative;
      padding: 0 0 0 20px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid $color-hues-green;
      }
      & + li {
        margin-top: 16px;
      }
    }
  }

  &__quote {
    margin-top: 24px;
    margin-bottom: 0 !important;
    position: relative;
    padding: 0 0 0 20px;
    font-size: 14px !important;
    line-height: 17px !important;
    &::before {
      position: absolute;
      content: "";
      left: 0;
      width: 4px;
      height: 100%;
      background-color: $color-shades-darkGray;
    }
  }
}
