@use "sass:map";

.modal__layout {
  background-color: hsl(0deg 0% 0% / 60%);
  z-index: 101;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  @include TL {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-centered {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @include TL {
      height: auto;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  background: map.get($colors, "shades", "white");
  z-index: 10;
  padding: 1rem;
  width: 100%;
  overflow: auto;
  max-width: 320px;
  min-height: 32rem;
  border-radius: 8px;
  gap: 1rem;

  @include TP {
    overflow: unset;
    padding: 1.5rem 2rem;
    width: 490px;
    max-width: 490px;
    min-height: 30rem;
  }

  @include TL {
    width: 520px;
    max-width: 520px;
  }

  &__large {
    @include TL {
      width: 1160px;
      max-width: 1160px;
    }
  }

  &__header {
    padding-bottom: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-weight: $font-weight-regular;
      line-height: 26px;
      text-align: left;
      text-transform: uppercase;

      @include TP {
        line-height: 34px;
      }

      @include DS {
        line-height: 38px;
      }
    }

    a {
      cursor: pointer;
      border: none;
      width: 21px;
      height: 21px;

      @include DS {
        width: 24px;
        height: 24px;
      }

      +* {
        margin-left: 0;
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  &__content {

    form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &__heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 0;
      border: none;
      padding: 1.5rem 1.5rem 1rem;
      gap: 1.5rem;

      &-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 5rem;
        height: 5rem;
        background-color: $color-shades-lightGray;
        border-radius: 50%;

        svg {
          width: 3rem;
          height: 3rem;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 0.5rem;

        p {
          font-size: 1.125rem;
          line-height: 1.56rem;

        }
      }
    }

    &__footer {
      width: 100%;
      padding-bottom: 1rem;

      button {
        width: 100%;
      }
    }

    &__section {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &.section-top {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
      }

      &__group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        &-flex {
          display: inline-flex;
          gap: 0.5rem;
        }

        &-h4 {
          margin-top: 1.75rem;
          margin-bottom: .5rem;
        }

        &-inline {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          @include TP {
            flex-direction: row;
          }
        }
      }
    }

    &-input {
      &+.modal__content-input {
        margin-top: 12px;
      }

      &+.modal__content-input__checkbox {
        margin-top: 24px;
      }

      select,
      option {
        background: map.get($colors, "shades", "white") !important;
        color: map.get($colors, "shades", "black") !important;
      }

      label,
      input,
      select {
        display: block;
      }

      label {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        padding-left: 8px;
      }

      input,
      select {
        position: relative;
        box-sizing: border-box;
        padding: 0 12px;
        left: -9px;
        border: 1px solid #0a0a29;
        border-radius: 4px;
        height: 36px;
        margin-top: 4px;
        width: 102%;

        @include DS {
          height: 40px;
        }
      }

      &-container {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;

        input {
          margin-right: -9px;
        }

        svg {
          position: absolute;
          right: 0;
          top: 4px;
          margin: 8px;
          cursor: pointer;

          &.d-none {
            display: none;
          }
        }
      }

      &+hr {
        margin: 24px auto;
      }

      &-first {
        margin-top: 24px;
      }
    }

    &-input__checkbox {
      display: block;
      position: relative;

      input[type="checkbox"] {
        appearance: checkbox !important;
      }

      &+.modal__content-input__checkbox {
        margin-top: 14px;

        @include TL {
          margin-top: 16px;
        }
      }

      label,
      input {
        display: flex;
      }

      label {
        font-weight: $font-weight-regular;
        line-height: 17px;
        padding-left: 0px;
        display: flex;
        align-items: center;
        padding-left: 2rem;
        padding-top: 9px;
      }

      input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;

        &:checked~.modal__content-input__checkmark {
          background-color: $color-hues-green;
        }

        &:checked~.modal__content-input__checkmark:after {
          display: block;
        }
      }
    }

    &-input__checkmark {
      cursor: pointer;
      position: absolute;
      top: 11px;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: map.get($colors, "shades", "white");
      border: 2px solid #0a0a29;
      border-radius: 4px;

      @include ML {
        top: 8px;
      }

      @include TP {
        top: 5px;
      }

      &:after {
        content: "";
        position: absolute;
        display: none;
      }

      &:after {
        content: "";
        left: 7px;
        top: 2px;
        width: 7px;
        height: 14px;
        border: solid #000;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &-input-group {
      width: 100%;

      @include TP {
        display: flex;
        margin-top: 16px;
        align-items: flex-end;
      }

      input {
        left: 0px;
        width: 100%;

        @include TP {
          margin-top: 0px;
          left: -9px;
        }
      }

      button {
        width: 100%;
        margin-top: 14px;
        display: none;

        @include TP {
          margin-left: 8px;
          display: flex !important;
          width: auto;
        }
      }
    }

    &__userlist {
      display: flex;
      flex-direction: column;
      margin: 1.5rem 0;
      gap: 1rem;

      &__user {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0.75rem;

        svg {
          width: 2rem;
          height: 2rem;
        }

        &-row {
          display: flex;
          align-items: center;
          svg {
            margin-right: .5rem;
          }
        }

        &-action {
          display: flex;
          align-items: center;
          svg {
            cursor: pointer;
            width: 21px;
            height: 21px;
            margin-left: .5rem;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    &-button {
      margin-top: 32px;

      button {
        width: 100%;
      }
    }

    &-subtext {
      margin-top: 24px;

      p {
        font-family: $font-family-secondary;
        color: $color-shades-darkGray;
        font-weight: $font-weight-regular;
        font-size: 13px;
        line-height: 16px;

        @include DS {
          font-size: 14px;
          line-height: 17px;
        }

        a {
          font-weight: $font-weight-medium;
          color: inherit;
          text-decoration: underline;
        }
      }
    }

    &-input-flex {
      display: flex;
      width: 100%;

      label:nth-child(1) {
        max-width: 64px;
      }

      label+label {
        margin-left: 3px;
        width: 100%;
      }
    }

    &-warning {
      display: flex;
      background-color: $color-hues-yellow;
      border-radius: 4px;
      align-items: center;
      padding: 8px;
      margin-top: 16px;
      margin-bottom: 24px;

      svg {
        margin-right: 8px;
      }

      p {
        font-family: $font-family-secondary;
        color: $color-shades-lightBlack;
        font-weight: $font-weight-regular;
        font-size: 14px;
        line-height: 17px;
      }
    }

    &-card {
      border: 1px solid $color-shades-lightGray;
      border-radius: 8px;
      height: 516px;

      &__header {
        background-color: $color-shades-lightGray;
        padding: 16px;
        text-align: center;
        border-radius: 8px 8px 0 0;

        h5 {
          font-family: $font-family-primary;
          text-transform: uppercase;
          font-weight: $font-weight-semi-bold;
          font-size: 18px;
          line-height: 22px;
          color: $color-shades-black;
        }
      }

      &__body {
        height: 462px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-list {
          &__item {
            display: flex;
            align-items: center;

            &+& {
              margin-top: 8px;
            }

            div {
              display: flex;

              svg {
                height: 24px;
                width: 24px;
                margin-right: 8px;
              }
            }

            p {
              font-weight: $font-weight-regular;
              font-size: 16px;
              line-height: 22px;
              color: $color-shades-lightBlack;
            }
          }
        }

        &-price {
          text-align: center;

          h4 {
            font-family: $font-family-secondary;
            font-weight: $font-weight-regular;
            font-size: 26px;
            line-height: 31px;
            text-align: center;
            color: $color-shades-black;
            margin-bottom: 4px;
          }

          h5 {
            font-weight: $font-weight-regular;
            font-family: $font-family-secondary;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: $color-shades-lightBlack;
          }

          button {
            font-family: $font-family-primary;
            cursor: pointer;
            font-weight: $font-weight-semi-bold;
            font-size: 14px;
            line-height: 16px;
            margin-top: 16px;
            width: 100%;
            height: 40px;
            text-align: center;
            color: $color-shades-black;
            border: 1px solid $color-shades-black;
            background: $color-shades-lightGray;
            opacity: 0.2;
          }
        }
      }

      &.active {
        border-color: $color-hues-green;
        box-shadow: 0px 4px 16px rgba(0, 255, 127, 0.2);

        .modal__content-card__header {
          background-color: $color-hues-green;
        }

        button {
          border: none;
          background: $color-hues-green;
          opacity: 1;
        }
      }
    }
  }

  &__information {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 0.5rem;
    background: $color-shades-lightGray;
    border-radius: 0.25rem;
    width: 100%;
    gap: 0.5rem;

    svg {
      width: 2rem;
      height: 2rem;
    }

    span {
      flex: 1;
    }
  }

  &__footer {
    width: 100%;
    margin-top: 2rem;
  }
}