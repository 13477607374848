@mixin badgeColors {
  color: $color-shades-black;
  font-weight: $font-weight-semi-bold;

  &.yellow {
    background-color: $color-hues-yellow;
  }
  &.green {
    background-color: $color-hues-green;
  }
  &.red {
    background-color: $color-flag-notify;
    color: $color-shades-white;
  }
  &.gray {
    background-color: $color-shades-darkGray;
    color: $color-shades-white;
  }
}

.badge {
  line-height: 1rem;
  padding: 0.25rem 0.5rem;
  text-align: center;
  border-radius: 0.25rem;
  display: inline-block;

  @include badgeColors();
}

.badge__small {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;

  @include badgeColors();
}

.badge-purchase {
  line-height: 17px;
  padding: 4px 8px;
  text-align: center;
  border-radius: 4px;

  @include TP {
    display: none;
  }

  @include DS {
    display: inherit;
    margin-left: 0px;
    text-align: left
  }

  @include badgeColors();
}

.badge__medium-purchase {
  position: relative;
  top: 2px;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin-left: 7px;
  display: none;

  @include TP {
    display: inline-block;
    margin-left: 0px;
  }

  @include DS {
    display: none;
  }

  @include badgeColors();
}
