.select {
  label,
  select {
    display: block;
    color: $color-shades-lightBlack;
  }

  label {
    font-family: $font-family-primary;
    font-size: 13px;
    line-height: 16px;
  }

  select {
    border: 0;
    padding-right: 2.25rem;
    font-family: $font-family-secondary;
    appearance: none;
    cursor: pointer;
    font-size: 15px;
    line-height: 24px;
    background-image: linear-gradient(
        45deg,
        transparent 50%,
        $color-shades-black 50%
      ),
      linear-gradient(135deg, $color-shades-black 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em),
      calc(100% - 14px) calc(1em), calc(100% - 2.5em) 0.5em;
    background-size: 6px 6px, 6px 6px, 1px 1.5em;
    background-repeat: no-repeat;
    height: 2.25rem;
    outline: none !important;
  }
}
