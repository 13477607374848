// Mixin to manage responsive breakpoints
// Media query manager
/*
> 0         MS - Mobile Small
> 378px     ML - Mobile Large
> 672px     TP - Tablet Portrait
> 980px     TL - Tablet Landscape
> 1200px    DS - Desktop Small
> 1568px    DL - Desktop Large
*/

// Breakpoints mixin
//
// Basic mixin of breakpoints. These can be used to configure 
// designs at different screen widths.

@mixin xxs {
	@media (min-width: map-get($breakpoints, "xs")) {
		@content;
	}
}
// xs
@mixin MS {
	@media (min-width: map-get($breakpoints, "ms")) {
		@content;
	}
}
// sm
@mixin ML {
	@media (min-width: map-get($breakpoints, "ml")) {
		@content;
	}
}
// md
@mixin TP {
	@media (min-width: map-get($breakpoints, "tp")) {
		@content;
	}
}
// lg
@mixin TL {
	@media (min-width: map-get($breakpoints, "tl")) {
		@content;
	}
}
// xl
@mixin DS {
	@media (min-width: map-get($breakpoints, "ds")) {
		@content;
	}
}
// xxl
@mixin DL {
	@media (min-width: map-get($breakpoints, "dl")) {
		@content;
	}
}