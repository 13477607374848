// Grid system imports
@use "sass:math";

// Grid system mixins
//
// Define the base col mixin

@mixin col-styles($i, $grid-columns) {
  box-sizing: border-box;
  flex-grow: 0;
  width: math.div($i * 100%, $grid-columns);
}

// Row default
//
// Define the base row styles.

.--row {
  display: flex;
  flex-flow: row wrap;
}

// Gutters classes
//
// Define the base gutters classes, adapting to
// different screen sizes.

@include xxs {
  .--gap-xxs > * {
    padding: map-get($grid-gutters, "xxs");
  }
  .--gap-xxs {
    margin-right: -(map-get($grid-gutters, "xxs"));
    margin-left: -(map-get($grid-gutters, "xxs"));
  }
}

@include MS {
  .--gap-xs > * {
    padding: map-get($grid-gutters, "xs");
  }
  .--gap-xs {
    margin-right: -(map-get($grid-gutters, "xs"));
    margin-left: -(map-get($grid-gutters, "xs"));
  }
}

@include ML {
  .--gap-sm > * {
    padding: map-get($grid-gutters, "sm");
  }
  .--gap-sm {
    margin-right: -(map-get($grid-gutters, "sm"));
    margin-left: -(map-get($grid-gutters, "sm"));
  }
}

@include TP {
  .--gap-md > * {
    padding: map-get($grid-gutters, "md");
  }
  .--gap-md {
    margin-right: -(map-get($grid-gutters, "md"));
    margin-left: -(map-get($grid-gutters, "md"));
  }
}

@include TL {
  .--gap-lg > * {
    padding: map-get($grid-gutters, "lg");
  }
  .--gap-lg {
    margin-right: -(map-get($grid-gutters, "lg"));
    margin-left: -(map-get($grid-gutters, "lg"));
  }
}

@include DS {
  .--gap-xl > * {
    padding: map-get($grid-gutters, "xl");
  }
  .--gap-xl {
    margin-right: -(map-get($grid-gutters, "xl"));
    margin-left: -(map-get($grid-gutters, "xl"));
  }
}

@include DL {
  .--gap-xxl > * {
    padding: map-get($grid-gutters, "xxl");
  }
  .--gap-xxl {
    margin-right: -(map-get($grid-gutters, "xxl"));
    margin-left: -(map-get($grid-gutters, "xxl"));
  }
}

// Justify content classes
//
// Define the base justify content classes, adapting to
// different screen sizes.

@include MS {
  @each $val in $layout-values {
    .--justify-xs-#{$val} {
      justify-content: $val;
    }
  }
}

@include ML {
  @each $val in $layout-values {
    .--justify-sm-#{$val} {
      justify-content: $val;
    }
  }
}

@include TP {
  @each $val in $layout-values {
    .--justify-md-#{$val} {
      justify-content: $val;
    }
  }
}

@include TL {
  @each $val in $layout-values {
    .--justify-lg-#{$val} {
      justify-content: $val;
    }
  }
}

@include DS {
  @each $val in $layout-values {
    .--justify-xl-#{$val} {
      justify-content: $val;
    }
  }
}

@include DL {
  @each $val in $layout-values {
    .--justify-xxl-#{$val} {
      justify-content: $val;
    }
  }
}

// Column classes
//
// Define the base column classes, adapting to
// different screen sizes.

@include MS {
  @for $i from 1 through $grid-columns {
    .--col-xs-#{$i} {
      @include col-styles($i, $grid-columns);
    }
  }
}

@include ML {
  @for $i from 1 through $grid-columns {
    .--col-sm-#{$i} {
      @include col-styles($i, $grid-columns);
    }
  }
}

@include TP {
  @for $i from 1 through $grid-columns {
    .--col-md-#{$i} {
      @include col-styles($i, $grid-columns);
    }
  }
}

@include TL {
  @for $i from 1 through $grid-columns {
    .--col-lg-#{$i} {
      @include col-styles($i, $grid-columns);
    }
  }
}

@include DS {
  @for $i from 1 through $grid-columns {
    .--col-xl-#{$i} {
      @include col-styles($i, $grid-columns);
    }
  }
}

@include DL {
  @for $i from 1 through $grid-columns {
    .--col-xxl-#{$i} {
      @include col-styles($i, $grid-columns);
    }
  }
}
