.progress {
  box-sizing: content-box;
  height: 0.5rem;
  position: relative;
  background: $color-shades-lightGray;
  border-radius: 0.25rem;
  overflow: hidden;

  & > span {
    display: block;
    height: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 0.25rem;
    background-color: $color-hues-green;
  }
}


