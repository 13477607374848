.toast {
  visibility: hidden;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  background-color: $color-shades-white;
  min-height: 52px;
  width: 398px;
  z-index: 999999;
  &.show {
    visibility: visible;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  &.fix {
    visibility: visible;
    animation: fadein 0.5s;
  }
  &.show,
  &.fix {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      top: -4px;
      width: 100%;
    }

    &.error {
      &::before {
        background-color: $color-flag-notify;
      }
    }
    &.success {
      &::before {
        background-color: $color-hues-green;
      }
    }
  }
  &-content {
    font-family: $font-family-secondary;
    padding: 12px 12px 12px 16px;
    background: $color-shades-white;
    border: 1px solid $color-shades-lightGray;

    .--row {
      justify-content: space-between;
      align-items: center;
    }

    p {
      font-weight: $font-weight-semi-bold;
      font-size: 14px;
      line-height: 17px;
      color: $color-shades-black;
    }

    button {
      background-color: transparent;
      border: none;
      width: 24px;
      height: 24px;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &-body-message {
      margin-top: 6px;
      margin-bottom: 12px;
      font-weight: 400 !important;

      & + button {
        width: auto;
        background-color: $color-shades-lightGray !important;
        border: 1px solid $color-shades-black !important;

        &:hover {
          background-color: $color-flag-notify !important;
          color: white !important;
        }
      }
    }
  }
}

// Animation
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 2rem;
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    bottom: 2rem;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
