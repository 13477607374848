.input {
  position: relative;
  width: 100%;

  label,
  select {
    display: block;
    color: $color-shades-lightBlack;
  }

  label {
    font-family: $font-family-primary;
    font-weight: 800;
    font-size: 0.75rem;
    line-height: 1.5rem;
  }

  input {
    width: 100%;
    font-family: $font-family-secondary;
    border: 1px solid $color-shades-lightBlack;
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    height: 2.5rem;
  }

  .password-visibility {
    display: flex;
    position: absolute;
    right: 0;
    top: 1.5rem;
    margin: 0.5rem;
    cursor: pointer;
    
    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0;
  
      &.d-none {
        display: none;
      }
    }
  }

  &.error {
    input {
      background-color: transparentize($color-flag-notify, 0.95);
      border-color: $color-flag-notify;
      color: $color-flag-notify;
    }
  }

  &.double {
    display: flex;

    &-left, &-left input {
      width: 64px;
      margin-right: 7px;

      @include DS {
        margin-right: 12px;
      }
    }

    &-right {
      width: 100%;
    }
  }
}
