.forgotten-password-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 22rem;
    text-align: center;
    
    svg {
      width: 10rem;
      height: 2rem;
      margin-bottom: 4rem;
    }

    &-action {
      text-align: left;

      &__head {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 1.5rem;
        gap: 0.5rem;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      }

      .input + button {
        width: 100%;
      }

      &__bottom {
        display: flex;
        justify-content: center;
        margin-top: 1rem;

        button.button-link {
          font-weight: 400;
          line-height: 17px;
          text-decoration-line: underline;
          color: $color-shades-black;
          height: 38px;
        }
      }
    }
  }
}
