.search {
	width: 100%;
	@include TL {
		width: 20rem;
	}

	.searchInput {
		display: inline-flex;
		width: 100%;
		border: 1px solid $color-shades-black;
		border-radius: 0.25rem;
		height: 2.5rem;
	}
	
	input {
		width: 100%;
		font-size: 1rem;
		font-family: "IBM Plex Sans";
		font-style: normal;
		font-weight: 400;
		line-height: 1rem;
		-webkit-appearance: none;
		border-radius: 0;
		all: unset;
	}
	
	.searchIcon {
		display: grid;
		place-items: center;
		padding-left: 0.5rem;
	}
	
	.closeIcon {
		display: none;
		margin-left: auto;
		place-items: center;
		padding-right: 0.5rem;
		cursor: pointer;
	
		&.icon-visible {
			display: grid;
		}
	}
}
